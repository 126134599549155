input,
textarea {
  background: transparent;

  /* Placeholder color */
  &::placeholder {
    @apply text-hint;
  }

  &::-moz-placeholder {
    @apply text-hint;
  }

  &::-webkit-input-placeholder {
    @apply text-hint;
  }

  &:-ms-input-placeholder {
    @apply text-hint;
  }

  &:-webkit-autofill {
    transition: background-color 600000s 0s, color 600000s 0s !important;
  }

  &:-webkit-autofill:hover {
    transition: background-color 600000s 0s, color 600000s 0s !important;
  }

  &:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s !important;
  }

  &:-webkit-autofill:active {
    transition: background-color 600000s 0s, color 600000s 0s !important;
  }

  [data-autocompleted] {
    background-color: transparent !important;
  }
}
