body {
  font-family: Helvetica, Arial, sans-serif !important;
}
.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  min-height: 36px !important;
}
a.mat-mdc-tooltip-trigger.fuse-vertical-navigation-item.ng-star-inserted.fuse-vertical-navigation-item-active {
  background-color: #52aa40 !important;
  color: white !important;
}
.backgr {
  background-color: #f8fafe !important;
}

.main-div {
  height: 136px !important;
}

.sec-div {
  height: 70px !important;
  margin-top: -30px !important;
}

.displays {
  display: contents !important;
}

.border {
  border-radius: 10px !important;
  margin: 10px 10px 0px 10px !important;
  overflow-y: auto !important;
}

.third-div {
  height: 90px !important;
  margin-top: -45px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  overflow: auto;
}

.for-div {
  margin-top: -30px !important;
}

.add-btn {
  background-color: #ffffff !important;
  width: 128px !important;
  height: 40px !important;
  border-radius: 20px !important;
  font-family: 'Helvetica' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.02em !important;
  color: #000000 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: 1px solid #80b93c !important;
}

.titles {
  font-family: 'Helvetica' !important;
  font-style: normal !important;
  display: flex !important;
  align-items: center !important;
}

.master {
  color: #77bc1f !important;
  font-size: 20px !important;
  padding-right: 10px !important;
  font-weight: 700 !important;
  line-height: 30px !important;
}

.currentModule {
  font-family: 'Helvetica' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 10px !important;
  letter-spacing: 0.02em !important;
  color: #1e293b !important;
  padding-right: 10px !important;
}

.selectedmodule {
  font-family: 'Helvetica' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 10px !important;
  letter-spacing: 0.02em !important;
  color: #77bc1f !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.icons {
  padding-right: 10px !important;
}

.table-box {
  background: #ffffff !important;
  box-shadow: 0 4px 20px #0000000d !important;
  border-radius: 10px !important;
}

.data {
  height: 30px !important;
}

.select {
  padding-left: 15px !important;
}

.select select {
  padding: 5px !important;
  box-sizing: border-box !important;
  background: #eff1f5 !important;
  border: 1px solid #aaaaaa !important;
  border-radius: 5px !important;
  width: 215px !important;
  height: 31px !important;
}

.flex-row {
  align-items: center !important;
}

.search {
  background: rgba(32, 36, 37, 0.04) !important;
  border: 1px solid #aaaaaa !important;
  border-radius: 20px !important;
  width: 253px !important;
  height: 40px !important;
  padding-left: 15px !important;
}

.searchinput {
  padding-left: 15px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 10px !important;
  letter-spacing: 0.02em !important;
  color: #1e293b !important;
}

.pointer {
  cursor: pointer !important;
}

.custom-style table {
  border-collapse: collapse !important;
  border-radius: 10px !important;
}

.custom-style table th,
.custom-style table td {
  text-align: left !important;
  border-bottom: 1px solid #ddd !important;
  border-bottom: 1px solid #ddd !important;
}

.custom-style table th {
  color: #f8fafe !important;
  align-items: center !important;
  text-transform: capitalize !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  font-family: 'Helvetica' !important;
  font-style: normal !important;
  line-height: 15px !important;
}

.custom-style table thead {
  height: 55px !important;
  background-color: #36393b !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;
  border-radius: 10px !important;
}

.custom-style table tbody {
  overflow: hidden !important;
}

.headers {
  height: 52px !important;
  width: 100% !important;
  background-color: #202425 !important;
  position: absolute !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.title {
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 15px !important;
  letter-spacing: 0.02em !important;
  color: #ffffff !important;
  padding-left: 33px !important;
}

.model {
  height: 100% !important;
}

.closebtn {
  padding-right: 18px !important;
}

.custom-button {
  color: #fff !important;
  text-transform: uppercase !important;
  cursor: pointer !important;
  border: none !important;
  outline: none !important;
  transition: all 0.3s ease-in-out !important;
  background: #77bc1f !important;
  box-shadow: 0px 4px 11px rgba(81, 86, 190, 0.5) !important;
  border-radius: 20px !important;
  width: auto !important;
  height: 45px !important;
  font-family: 'Helvetica' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 15px !important;
}

// mat-step-icon-selected {
//   background-color: #7abe24 !important;
//   color: #fff !important;
// }

.custom-button:hover {
  background-color: #61a30b !important;
  color: #fff !important;
}

.cancel-button {
  width: auto !important;
  height: 45px !important;
  background: #ffffff !important;
  font-family: 'Helvetica' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 15px !important;
  color: #202425 !important;
  border-radius: 20px !important;
  border: 1px solid #77bc1f !important;
  cursor: pointer !important;
  // filter: drop-shadow(0px 4px 11px rgba(81, 86, 190, 0.5)) !important;
}

.cancel-button:hover {
  background-color: #ffffff !important;
  color: #1e293b !important;
  filter: drop-shadow(0px 4px 11px rgba(81, 86, 190, 0.5)) !important;
}

// .theme-default.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
//   padding-left: 10px !important;
// }

.record {
  width: 107px !important;
  height: 31px !important;
}

.form-field {
  display: flex !important;
  flex-direction: column !important;
  margin-bottom: 16px !important;
  flex-basis: 48% !important;
}

.grid {
  grid-gap: 0 20px !important;
}

/* You can add global styles to this file, and also import other style files */
// mat-form-field .mat-mdc-text-field-wrapper {
//   height: 42px !important;
// }

// .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
//   padding-top: 8px;
//   padding-bottom: 8px;
// }

// .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
//   top: 18px;
// }

// .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
//   border-top-left-radius: 32px !important;
//   border-top-right-radius: 0 !important;
//   border-bottom-right-radius: 0 !important;
//   border-bottom-left-radius: 32px !important;
// }

// .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
//   border-top-left-radius: 0 !important;
//   border-top-right-radius: 10px !important;
//   border-bottom-right-radius: 10px !important;
//   border-bottom-left-radius: 0 !important;
// }

// .mat-mdc-select-arrow-wrapper {
//   padding-bottom: 10px;
// }

// .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper:not(.mdc-text-field--no-label) {
//   margin-top: 21px !important;
//   overflow: visible !important;
//   border-radius: 10px !important;
//   border: 1px solid rgba(7, 7, 7, 0.4) !important;
//   font-size: 12px !important;
// }

// .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper:not(.mdc-text-field--no-label) input:focus {
//   border-color: green !important;
// }

// .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-value .mat-mdc-select-value-text > * {
//   padding-bottom: 10px !important;
// }

// .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label {
//   font-family: 'Helvetica' !important;
//   font-style: normal !important;
//   font-weight: 400 !important;
//   font-size: 12px !important;
//   line-height: 15px !important;
//   align-items: center !important;
//   text-transform: capitalize !important;
//   color: #1e293b !important;
// }

.mat-step-icon .mat-icon {
  background-color: #7abe24 !important;
  border-radius: 50px !important;
}

.theme-default .light .mat-step-header .mat-step-icon,
.theme-default.light .mat-step-header .mat-step-icon {
  background-color: #202425 !important;
  color: #ffffff !important;
}

// .mat-step-text-label {
//   text-overflow: ellipsis !important;
//   overflow: hidden !important;
//   font-family: 'Helvetica' !important;
//   font-style: normal !important;
//   font-weight: 400 !important;
//   font-size: 16px !important;
//   line-height: 15px !important;
//   letter-spacing: 0.02em !important;
//   color: #202425 !important;
//   width: 100px;
// }

// .mat-horizontal-stepper-header {
//   width: 131px !important;
//   justify-content: center !important;
//   height: 42px !important;
//   width: 105px !important;
//   margin-top: 15px !important;
// }

// .theme-default.light .mat-stepper-horizontal-line {
//   border-top-color: #202425 !important;
//   height: 1px;
//   margin: 20px 0 0 0px !important;
// }

// .mat-horizontal-stepper-header.mat-step-header[aria-selected='true'] {
//   background-color: rgba(122, 190, 36, 0.05) !important;
//   border-radius: 6px !important;
// }

// .mat-mdc-option {
//   min-height: 30.11px;
//   min-height: 30px !important;
//   background-color: transparent !important;
// }

// .theme-default.light .mat-primary .mat-pseudo-checkbox-checked {
//   background-color: #000000 !important;
// }

// .theme-default.light .mat-primary .mat-pseudo-checkbox-checked + .mat-mdc-option,
// .theme-default.light .mat-primary .mat-pseudo-checkbox-checked ~ .mat-mdc-option {
//   background-color: #000000 !important;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 15px;
// }

// .theme-default.light .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
//   font-family: 'Helvetica' !important;
//   font-style: normal !important;
//   font-weight: 400 !important;
//   font-size: 14px !important;
//   line-height: 15px !important;
//   align-items: center !important;
//   text-transform: capitalize !important;
//   color: #000000 !important;
// }

// .theme-default.light .mat-mdc-option:hover,
// .mat-mdc-option.mat-mdc-option-active {
//   background: rgb(0 0 0 / 0%) !important;
// }

// .mat-mdc-option:hover {
//   background-color: #52aa40 !important;
// }

.theme-default .light .mdc-menu-surface,
.theme-default.light .mdc-menu-surface {
  border-radius: 8px !important;
  min-height: 0px;
  max-height: 266px;
}

// .theme-default.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
//   /* color: #000000de; */
//   font-size: 20px !important;
//   font-weight: 400 !important;
//   font-size: 16px !important;
//   line-height: 10px !important;
//   // padding-bottom: 11px !important;
//   color: #1e293b !important;
// }

// .theme-default.light .mat-mdc-option:hover {
//   background-color: #77bc1f !important;
// }

// .mat-mdc-option:hover {
//   font-size: 16px !important;
//   color: #ffffff !important;
// }

// .fuse-vertical-navigation-basic-item>.fuse-vertical-navigation-item-wrapper .fuse-vertical-navigation-item:not(.fuse-vertical-navigation-item-disabled).fuse-vertical-navigation-item-active{
//   background-color: #77bc1f !important;
//   color: white !important;
//   font-family: 'Helvetica' !important;
//   font-style: normal !important;
//   font-weight: 700 !important;
//   font-size: 13px !important;
//   line-height: 20px !important;
// }

.theme-default.light .mat-mdc-paginator {
  background-color: #f8fafe !important;
}

.mat-mdc-paginator .mat-mdc-paginator-container {
  justify-content: end !important;
}

.mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-range-actions {
  margin: 0px !important;
}

.theme-default .light .mat-mdc-paginator,
.theme-default.light .mat-mdc-paginator {
  background-color: #f8fafe !important;
}

.mat-mdc-paginator .mat-mdc-paginator-container {
  justify-content: end !important;
}

.mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-range-actions {
  margin: 0px !important;
}

// .theme-default.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
//   padding-top: 0px !important;
// }

.green-snackbar {
  color: #155724 !important; // green
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;

  .mat-simple-snackbar-action {
    color: #155724 !important;
  }
}

.borders {
  border-radius: 10px !important;
  margin: 10px 10px 0 !important;
  overflow-y: clip !important;
  border: 1px solid grey !important;
}

.new-search {
  // box-sizing: border-box;

  // position: absolute;
  width: 253px;
  height: 40px;
  // left: 1081px;
  // top: 93px;

  border: 1px solid #80b93c;
  border-radius: 20px;
}

.line {
  margin-left: 190px;
}

.iconssss {
  margin-right: 10px;
}

.input-feild {
  height: 20px;
  width: 8px;
}

.mat-mdc-snack-bar-container {
  &.green-snackbar {
    --mdc-snackbar-container-color: green !important;
    --mat-mdc-snack-bar-button-color: #fff !important;
    --mdc-snackbar-supporting-text-color: #fff !important;
  }
}

.mat-mdc-snack-bar-container {
  &.error-snackbar {
    --mdc-snackbar-container-color: red !important;
    --mat-mdc-snack-bar-button-color: #fff !important;
    --mdc-snackbar-supporting-text-color: #fff !important;
  }
}

.mat-mdc-snack-bar-container {
  &.warning-snackbar {
    --mdc-snackbar-container-color: orange !important;
    --mat-mdc-snack-bar-button-color: #fff !important;
    --mdc-snackbar-supporting-text-color: #fff !important;
  }
}

hr {
  margin-left: -32px !important;
  margin-right: -32px !important;
}

.mat-horizontal-stepper-header-container {
  padding-top: 1rem !important;
}

.my-custom-tooltip {
  max-width: 800px !important;
  max-width: fit-content !important;
  max-height: unset !important;
}

// .footer-paginator {
//   bottom: 12px;
//   position: fixed;
//   right: 0px;
// }

.scrollable-dialog {
  overflow-y: auto;
  /* Enable vertical scrolling */
}

.mdc-evolution-chip-set .mdc-evolution-chip {
  margin-top: -2px !important;
}

.scroll-card {
  width: 900px;
  overflow: hidden;
  display: flex;
  background-color: transparent !important;
}
